.contactus {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* font-family: Arial, sans-serif; */
    margin-top: 80px;
}

.contactus .contactus-container {
    width: 100%;
    max-width: 600px;
    /* background-color: #f9f9f9; */
    padding: 30px;
    border-radius: 8px;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.contactus h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
}

.contactus .contact-form {
    display: flex;
    flex-direction: column;
}

.contactus .form-group {
    margin-bottom: 15px;
}

.contactus .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #555;
}

.contactus .form-group input, .contactus .form-group textarea {
    width: 100%;
    width: calc(100% - 22px);;
    /* width: 100%; */
    /* height: 40px; */
    /* padding-block: 0px;  */
    /* padding-inline: 40px; */
    padding: 12px 14px 12px  ;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.contactus .form-group textarea {
    resize: vertical;
}

.contactus .submit-button {
    padding: 10px 20px;
    background-color: #3182ce;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.contactus .submit-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.contactus .success-message {
    color: green;
    margin-top: 10px;
}

.contactus .error-message {
    color: red;
    margin-top: 10px;
}

