/* @import url('https://fonts.googleapis.com/css2?family=Freestyle+Script&display=swap'); */


@font-face {
  font-family: 'Freestyle Script Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Freestyle Script Regular'), url('../../fonts/FREESCPT.woff') format('woff');
  }

.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 20px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    position: fixed; /* Fixes the top bar */
    top: 0; /* Sticks it to the top of the viewport */
    width: 100%; /* Ensure it spans the full width of the viewport */
    height: 80px;
    z-index: 1000; /* Ensures it stays above other content */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  }
  
  /* Add padding to the top of the page to prevent content hiding behind the top bar */
  .topbar body {
    padding-top: 100px; /* Adjust based on the height of your top bar */
  }
  
  
  .topbar .logo-section {
    display: flex;
    align-items: center;
  }
  
  .topbar .logo {
    width: 80px;
    height: 80px;
    /* margin-right: 15px; */
  }
  
  .topbar .title {
    font-size: 50px;
    font-weight: 200;
    margin: 0;
    margin-bottom: 0;
    font-family: 'Freestyle Script Regular';

  }
  
  .topbar .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .topbar .nav-links a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s;
  }
  
  .topbar .nav-links a:hover {
    color: #ddd;
  }

  /* Media query for smaller screens */
  @media (max-width: 600px) {
   
    /*  */
 
    .topbar .nav-links {
      flex-direction: column; /* Stack items vertically on small screens */
      gap: 5px; /* Add space between items */

    }
  }


  