/* Dashboard.css */

/* Existing Styles */

.dashboard {
    display: flex;
    flex-direction: row;
    margin-top: 80px; /* Adjust based on the height of your top bar */
    /* height: 1000 px; */
}

.dashboard .sidebar {
    width: 200px;
    background-color: #f4f4f4;
    /* padding: 20px; */
    /* height: 870px; Adjust based on the height of your top bar */
}

.dashboard  .content {
    flex-grow: 1;
    /* padding: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 950px; */
}

.dashboard  .no-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard .sidebarElements{
    padding-inline-start: 10px;
    padding-inline-end: 10px;
}

.dashboard .filters {
    margin-bottom: 20px;
}

.dashboard .filters label {
    margin-right: 20px;
}

.dashboard .filters select {
    padding: 5px;
    margin-left: 10px;
}

/* Enhanced Styles */

/* Logout Button Styling */
.dashboard .logout-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between icon and text */
    width: 100%;
    padding: 12px 20px;
    background-color: #fff; /* Red background */
    color: red;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .dashboard .logout-button .fa-sign-out-alt {
    font-size: 18px; /* Adjust icon size if necessary */
  }
  

.dashboard .logout-button:hover {
    background-color: #bbbaba; /* Darker red on hover */
    transform: translateY(-2px); /* Slight lift on hover */
}

.dashboard .logout-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.5); /* Focus ring for accessibility */
}

.dashboard .logout-button:active {
    background-color: #9b2c2c; /* Even darker red on active */
    transform: translateY(0); /* Remove lift on active */
}

/* Login Container Styling */
.dashboard .login-container {
    width: 50%;
    margin-top: 20px;
    min-width: 300px;
    /* max-width: 500px; */
    padding: 30px;
    border-radius: 12px;
    background-color: #ffffff; /* White background for contrast */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard .login-container:hover {
    /* transform: translateY(-5px); Slight lift on hover */
    /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); Deeper shadow on hover */
}

.dashboard .login-container h1 {
    margin-bottom: 25px;
    font-size: 2em;
    /* font-size: 60px; */
    color: #2d3748; /* Dark gray for the title */
}

/* Form Group Styling */
.dashboard .form-group {
    
    margin-bottom: 20px;
    text-align: left; /* Align labels and inputs to the left */
}

.dashboard .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #4a5568; /* Medium gray for labels */
}

.dashboard .form-group input {
    width: calc(100% - 30px);;
    /* width: 100%; */
    /* height: 40px; */
    /* padding-block: 0px;  */
    /* padding-inline: 40px; */
    padding: 12px 14px 12px  ;
    border-radius: 6px;
    border: 1px solid #cbd5e0; /* Light gray border */
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.dashboard .form-group input:focus {
    border-color: #3182ce; /* Blue border on focus */
    outline: none;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.3); /* Focus ring */
}

/* Error Message Styling */
.dashboard .error-message {
    color: #e53e3e; /* Red color for errors */
    margin-bottom: 20px;
    font-weight: 500;
}

/* Submit Button Styling */
.dashboard .login-button {
    width: 100%;
    padding: 12px 20px;
    background-color: #3182ce; /* Blue background */
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.dashboard .login-button:hover:not(:disabled) {
    background-color: #2b6cb0; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift on hover */
}

.dashboard .login-button:disabled {
    background-color: #a0aec0; /* Gray background when disabled */
    cursor: not-allowed;
}

.dashboard .login-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.5); /* Focus ring for accessibility */
}

.dashboard .login-button:active {
    background-color: #2c5282; /* Even darker blue on active */
    transform: translateY(0); /* Remove lift on active */
}


/* Responsive Design */
@media (max-width: 768px) {
    .dashboard .login-container {
        width: 80%;
    }

    .dashboard .sidebar {
        width: 150px;
    }

    .dashboard {
        flex-direction: column;
    }

    .dashboard .sidebar {
        width: 100%;
        height: auto;
    }

    .dashboard .content {
        padding: 10px;
    }
}
