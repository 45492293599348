
/* Add this CSS to your existing styles */
/* .div{
    align-items: center;
} */
@font-face {
  font-family: 'Freestyle Script Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Freestyle Script Regular'), url('../../fonts/FREESCPT.woff') format('woff');
  }

.section {
    display: flex;
    align-items: center; /* Vertically center the content */
    flex-wrap: wrap; /* Allow items to wrap */
    max-width: 98%; /* Set your desired maximum width */
    margin: 0 0; /* Center the section */
    padding: 20px; /* Add some padding */
    /* background-image: url("/background.png") ; */
    /* background-image: url("https://media.wired.com/photos/598e35fb99d76447c4eb1f28/master/pass/phonepicutres-TA.jpg") ; */
    /* background-size: cover; */
    /* background-image: url('/background.png'); */
    /* background-image: url('/couple.webp'); */
    /*background-size: cover;
    background-position: center; */
    /* background-image: '/yourPathHere.jpg' */
}

/* Text container styles */
.section div {
    text-align: center; /* Center text horizontally */
    flex: 1; /* Allow the text container to grow */
    font-size: 50;
    

}

.section h1 {
    font-size: 150px;
    font-weight: 200;
    font-family: 'Freestyle Script Regular';

    /* font-family: ; */
    /* color: #ffffff; */
    color: #000;

    margin-bottom: 20px;
    text-align: center;
 }
 .section p{
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
 }
.main-img{
    padding-top: 40px;
    padding-bottom: 20px;
    max-width: 100%;
     max-height: 700px;
     border-radius: 50px;
   
   }
/* Media query for smaller screens */
@media (max-width: 600px) {
    .section {
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: flex-start; /* Align items to the start */
    }

    .main-img {
        margin-right: 0; /* Remove margin for small screens */
        margin-bottom: 0px; /* Add space below the image */
    }
}



/* .section {
    display: flex;
    align-items: center; 
    flex-wrap: wrap;
} */

/* FeatureContainer */
.feature-container {
    width: 30%;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* SubscribeSection */
  .subscribe-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    background-color: #0000000c;
    color: #ffffff;
    color: #000;
  }
  
  /* SubscribeForm */
  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  /* Input */
  .input {
    padding: 10px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* SubmitButton */
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* DownloadButtons */
  .download-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

  }
  
  /* DownloadButton */
  .download-button {
    display: inline-block;
    padding: 5px 5px 10px 5px;
    /* background-color: #007bff; */
    color: white;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    background-position: 0%;
    background-size: contain;
    height: 40px;
    width: 150px;
  }
  .download-buttom-img {
    width: 150px;
    height: auto;
  }
  
  /* Container */
.container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    min-height: 100vh;
  }
/* Target the slider container */
.slick-slider {
    box-sizing: border-box; /* Apply box-sizing to the slider */
    width: 98%; /* Ensure the slider uses the full width of the container */

}

/* Prevent overflow on slider items */
.slick-slide {
    overflow: hidden; /* Prevent overflow from the slides */
}

/* Optional: You might also want to set some styles for the slider items */
.feature-container {
    padding: 0px; /* Add padding if needed */
}

