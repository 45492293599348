/* PrivacyPolicy.css */

/* Container for the PrivacyPolicy page */
.PrivacyPolicy {
    background-color: #ffffff;
    color: #000000;
    min-height: 100vh;
    /* font-family: Arial, sans-serif; */
  }
  
  /* Content wrapper to center the content and provide padding */
  .PrivacyPolicy-content {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 100px;
    align-items: center;
    padding: 20px;
  }
  
  /* Title styling */
  .PrivacyPolicy-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
  }
  
  .PrivacyPolicy-parargraph {
    text-align: center;
    margin-bottom: 10px;
    /* font-size: 1.5em; */
    border-bottom: 2px solid #000;
    padding-bottom: 20px;
  }
  /* Remove default list styling and reset counters */
  .PrivacyPolicy-list {
    list-style: none;
    padding: 0;
    counter-reset: list-counter;
  }
  
  /* Individual list items */
  .PrivacyPolicy-listItem {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  /* Header of each policy item */
  .PrivacyPolicy-itemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  /* Numbering for each item */
  .PrivacyPolicy-itemNumber {
    font-weight: bold;
    margin-right: 10px;
  }
  
  /* Title of each policy item */
  .PrivacyPolicy-itemTitle {
    flex-grow: 1;
  }
  
  /* Toggle icon styling */
  .PrivacyPolicy-toggleIcon {
    font-size: 1.5em;
    width: 20px;
    text-align: center;
  }
  
  /* Content of each policy item */
  .PrivacyPolicy-itemContent {
    margin-top: 10px;
    padding-left: 30px;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Fade-in animation for expanded content */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  